.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin: 20px auto;
    list-style: none;
    cursor: pointer;
    max-width: 100%;
  }


  .pagination li{
    display: flex;
    justify-content: center;
    align-items:center ;
  }
  .pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #6c7ac9;
    color: #6c7ac9;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #6c7ac9;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
  