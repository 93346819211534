@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url("./images/background.png");
  background-size: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Ubuntu:wght@300&display=swap");

body,
html {
  font-family: "Ubuntu", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}
